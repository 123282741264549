<template>
  <div class="main" v-if="!dialogFormVisible">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
          placeholder="姓名/电话/身份证号" />

        <el-select class="select-width" size="medium" v-model="queryForm.sendStatus" placeholder="状态" clearable>
          <el-option v-for="(item, v) in statusList" :key="v" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-cascader v-model="queryForm.regOrgCode" placeholder="部门" style="width:200px;" :options="cdList" clearable
          :props="{ checkStrictly: true ,label:'orgName',value:'orgCode'}" :show-all-levels="false" filterable>
        </el-cascader>

        <el-date-picker style="margin-left:20px;" v-model="pickerTime" size="medium" type="daterange"
          value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />

      </div>

      <div class="right-item" style="display:flex;">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <!-- <el-button type="primary" size="small" @click="importfile">导入</el-button> -->
        <el-upload style="margin:0 10px;" class="btns-item upload-item" :action="upload" accept=".xls, .xlsx"
          :show-file-list="false" :headers="myHeaders" :on-success="handelSuccess">
          <el-button type="primary">导入</el-button>
        </el-upload>
        <el-button type="primary" size="small" @click="addUserInfo">新增</el-button>
        <el-button type="primary" size="small" @click="exportFormInfo">下载导入模板</el-button>

      </div>
    </div>

    <el-table height="100%" :data="tableData" row-key="id" default-expand-all border
      @selection-change="auditBatchRelieveIsolate">
      <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="操作" width="100">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)" style="margin-right:10px;">编辑</el-link>
          <el-link type="danger" @click="editUserStatus2(row)">删除</el-link>

        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="sex" :formatter="sexFilter" label="性别" width="60" />
      <el-table-column prop="cardType" :formatter="zjFilter" label="证件类型"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>、
      <el-table-column prop="cardNo" label="身份证号" width="180"></el-table-column>
      <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
      <el-table-column prop="currentAddressDetail" label="现居住地址" width="120"></el-table-column>
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column prop="nation" label="民族"></el-table-column>
      <el-table-column prop="regUser" label="登记人"></el-table-column>
      <el-table-column prop="regTime" label="登记时间" width="120"></el-table-column>
      <el-table-column prop="orgName" label="登记机构"></el-table-column>

    </el-table>

    <div class="content-page">
      <div class="operation-text" style="float:left;">
        <span>已选中{{ ids.length }}项目</span>
        <span style="margin-left:20px">批量操作</span>
        <el-button type="primary" size="mini" style="margin-left:20px" :disabled="!(ids.length > 0)"
          @click="importfiledialog2 = true">居家隔离</el-button>
        <el-button type="primary" size="mini" style="margin-left:20px" :disabled="!(ids.length > 0)"
          @click="importfiledialog3 = true">集中隔离</el-button>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber" :page-size="pageObj.pageSize" :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <!-- 导入 -->
    <el-dialog title="文件上传" :visible.sync="importfiledialog" width="30%">
      <div class="wjscc">
        <!-- <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload> -->

        <el-upload class="btns-item upload-item" :action="upload" accept=".xls, .xlsx" :show-file-list="false"
          :headers="myHeaders" :on-success="handelSuccess" :on-error="handelError">
          <el-button type="primary" style="width: 120px">导入</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importfiledialog = false">取 消</el-button>
        <el-button type="primary" @click="swddd">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="居家隔离" :visible.sync="importfiledialog2" width="500px">
      <div>
        <el-form>
          <el-form-item label="负责机构：" prop="orgCode">
            <el-select style="width:220px" @change="dialog2Change" v-model="dialog2Obj.org" value-key="orgCode" filterable
              clearable placeholder="请选择">
              <el-option v-for="item in xqsqsjoptionscx" :key="item.orgCode" :label="item.orgName" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理类型：" prop="controlMethod">
            <el-radio-group v-model="dialog2Obj.controlMethod">
              <el-radio label="1">居家监测 </el-radio>
              <el-radio label="2">居家隔离</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importfiledialog2 = false">取 消</el-button>
        <el-button type="primary" @click="dialog2Fun">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 集中隔离弹出框 -->
    <el-dialog title="集中隔离" :visible.sync="importfiledialog3" width="500px">
      <div class="islaotion">
        <el-form :rules="rules">
          <el-form-item label="" prop="orgCode">
            <el-form-item label="是否转运：" prop="transportFlag">
              <el-radio-group @change="istrans()" v-model="dialog3Obj.transportFlag">
                <el-radio label="1">是 </el-radio>
                <el-radio label="0">否 </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="隔离点：" v-if="dialog3Obj.transportFlag==0" prop="">
              <el-select clearable filterable class="select-width" size="medium" v-model="dialog3Obj.org"
              @change="dialog3Change" value-key="orgCode" placeholder="隔离点">
              <el-option v-for="item in allIsolationList" :key="item.id" :label="item.orgName" :value="item" />
            </el-select>
            </el-form-item>

            <el-form-item label="转运负责机构：" v-if="dialog3Obj.transportFlag==1" prop="">
              <el-select clearable filterable class="select-width" size="medium" v-model="dialog3Obj.org"
              @change="dialog3Change" value-key="orgCode" placeholder="转运负责机构">
              <el-option v-for="item in concentrateOrgList" :key="item.id" :label="item.orgName" :value="item" />
            </el-select>
            </el-form-item>

          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importfiledialog3 = false">取 消</el-button>
        <el-button type="primary" @click="dialog3Fun">确 定</el-button>
      </span>
    </el-dialog>

  </div>
  <div v-else>
    <info :dialogFormVisibleId="dialogFormVisibleId" :dialogFormVisibleType="dialogFormVisibleType"></info>
  </div>
</template>

<script>
import { _debounce } from '@/utils/utils.js'
const formObj = { //表单
  name: "",
  cardType: "",
  cardNo: "",
  sex: "",
  age: "",
  linkPhone: "",
  country: "",
  nation: "",
  currentAddressDetail: "",
  crowdTypeId: "",

  ivingArea: "",
  riskLevel: "",
  traffic: "",
  trafficNo: "",
  seatNo: "",
  leaveTime: "",
  arrivalTime: "",
  householdPlace: "",
  destination: "",
  destinationCode: "",

  basicIllnessFlag: "",
  basicIllness: [],
  personType: "",
  remark: "",
};

const perTypeListJSON = [ //表单
  {
    value: "1",
    label: "社区人员"
  },
  {
    value: "2",
    label: "司机"
  },
  {
    value: "3",
    label: "隔离酒店人员"
  },
  {
    value: "4",
    label: "疾控中心人"
  },
  {
    value: "5",
    label: "转运调度人员"
  },
];

const tableFromObj = {
  "orgId": "",
  "peopleType": "",
  "roleId": ""
};

import {
  getSysOrgTreeListByOrgCode,
  getAllIsolationOrgList,
  getroleList,
  userSave,
  userUpdate,
  userUpdateStaus,
  getUserOrgRelationList,
  saveUserOrgRelation,
  getBusinessOrgList //根据业务状态查询机构列表
} from "../../api/SystemManagement/index.js";



import {
  reportList,
  exportStreetRegTemplate,
  uploadStreetRegExcel,
  batchIsolationByHome,
  batchIsolationByHotel,
  getRoleRes,
  streetregSave,
  streetUpdate,
  streetDelete
} from "@/api/streetSegregation/index.js";

import constants from "../../common/constants";
import {
  mapState
} from "vuex";
import { getToken } from "@/utils/auth";
import { importFile, messageBox } from '../../utils/utils';
import info from "./info";

export default {
  name: "UserManage",
  components: {
    info
  },
  data() {
    return {
      rules:{
      org:[{required:true}],
      orgCode:[{required:true}]
      },
      concentrateOrgList: [], //根据转运业务状态查询的下拉框
      upload: "/ohealth/api/v1/epidemicControl/streetreg/uploadStreetRegExcel",
      //上传文件请求头
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      tableData: [], //表单数据
      pageObj: {
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      queryForm: {
        searchValue: "",
        sendStatus: "0",
        regTimeMax: "",
        regTimeMin: "",
      },
      pickerTime: [],
      statusList: [
        {
          value: "",
          label: "全部 "
        },
        {
          value: "0",
          label: "待推送"
        },
        {
          value: "1",
          label: "完成"
        },
      ],
      cdList: [],
      dialogFormVisible: false,
      dialogFormVisibleType: 'add',
      dialogFormVisibleId: '',

      formInfo: formObj,
      dialogFormVisible2: false,
      dialogFormVisible2Id: "",
      roleList: [],
      perTypeList: perTypeListJSON,
      tableFrom: [],

      ids: [],
      importfiledialog: false,

      importfiledialog2: false,
      dialog2Obj: {
        org: '',
        orgCode: '',
        orgName: '',
        controlMethod: '2',
      },
      xqsqsjoptionscx: [],

      importfiledialog3: false,
      dialog3Obj: {
        org: '',
        orgCode: '',
        orgName: '',
        transportFlag: '0',
      },

      allIsolationList: [],

    };
  },

  computed: {
    // ...mapState({
    // }),
  },

  created() {
    this.getUserInfo();
    this.cdListFun();
    this.getRoleList();
    this.getBusinessOrgList();
    this.allIsolationListFun(); //是否转运进来默认 否
  },


  methods: {
    // 转运判断
    istrans(){
      if(this.dialog3Obj.transportFlag==1){
        this.TransOrgList();
        this.dialog3Obj.org='';
      }else{
        this.allIsolationListFun();
        this.dialog3Obj.org='';
      }
    },

    // 根据业务状态查询业务机构列表(查询转运任务业务机构列表)jzy
    async TransOrgList() {
      let params = { transshipmentTask: 1 }
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.concentrateOrgList = data.data
      }
    },

    // 隔离点列表
    async allIsolationListFun() {
      try {
        let { data } = await getAllIsolationOrgList();
        this.allIsolationList = data.data;
      } catch (error) { }
    },

    dialog2Change(val) {
      console.log(val);
      this.dialog2Obj.orgName = val.orgName
      this.dialog2Obj.orgCode = val.orgCode
      console.log(this.dialog2Obj);
    },

    dialog3Change(val) {
      // console.log(val, '1234567890987654321');
      this.dialog3Obj.orgName = val.orgName
      this.dialog3Obj.orgCode = val.orgCode
    },

    dialog2Fun:_debounce(function() {
      console.log(1111111, this.ids);
      let params = Object.assign({ ids: this.ids }, this.dialog2Obj);
      batchIsolationByHome(params).then(res => {
        let {
          code,
          data,
          msg
        } = res.data;
        console.log("getRoleList -> res.data", res.data);
        if (code == 200) {
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.importfiledialog2 = false;
          this.getUserInfo();
        }
      });
    },300),

    dialog3Fun:_debounce(function() {
      console.log(1111111, this.ids);
      let params = Object.assign({ ids: this.ids }, this.dialog3Obj);
      if(this.dialog3Obj.org!=''){
        batchIsolationByHotel(params).then(res => {
        let {
          code,
          data,
          msg
        } = res.data;
        console.log("getRoleList -> res.data", res.data);
        if (code == 200) {
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.importfiledialog3 = false;
          this.getUserInfo();
        }
      });
      }else{
        this.$message({
          message: '请选中转运机构或者隔离点',
          type: "error",
        });
      }
      
    },300),
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.getUserInfo();
        // this.tempFileList.push({
        //   name: file.response.data.originalName,
        //   url: file.response.data.imgUrl,
        // });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    //文件上传失败
    handelError(error) {
      console.log(error);
      // if (res.code == 200) {
      //   this.$message({
      //     message: "上传失败",
      //     type: "error",
      //   });
      // }
    },
    sexFilter(row) {
      let sex = row.sex;
      const typeMap = {
        '1': "男",
        '0': "女",
      };
      return typeMap[sex] || "未知";
    },
    zjFilter(row) {
      let cardType = row.cardType;
      const cardTypeList = [ //表单
        {
          value: "1",
          label: "居民身份证"
        },
        {
          value: "2",
          label: "护照"
        },
        {
          value: "3",
          label: "港澳通行证"
        },
        {
          value: "4",
          label: "台胞证"
        },
        {
          value: "5",
          label: "其他"
        },
      ];
      let obj = cardTypeList.find((item) => {
        return item.value == cardType;
      })
      return obj.label || "";
    },
    cdListFun() {
      getSysOrgTreeListByOrgCode().then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.cdList = data;
          console.log(
            "🚀 ~ file: menuManage.vue ~ line 255 ~ getAllSysOrgTreeList ~ this.cdList",
            this.cdList
          );
        }
      });
    },
    getUserInfo() {
      let params = Object.assign({}, this.queryForm, this.pageObj);
      if (this.pickerTime && this.pickerTime.length == 2) {
        params.regTimeMin = this.pickerTime[0] + " 00:00:00";
        params.regTimeMax = this.pickerTime[1] + " 23:59:59";
      }
      if (Object.prototype.toString.call(params.regOrgCode) === '[object Array]') {
        params.regOrgCode = params.regOrgCode[params.regOrgCode.length - 1]
      }
      reportList({
        params,
      }).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.tableData = data.list;
          this.pageObj.total = Number(data.totalRow);
        }
      });
    },

    // 角色列表
    getRoleList() {
      getroleList().then(res => {
        let {
          code,
          data,
          msg
        } = res.data;
        console.log("getRoleList -> res.data", res.data);
        if (code == 200) {
          this.roleList = data;
        }
      });
    },

    //查询
    queryUserInfo() {
      this.getUserInfo();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      // this.formInfo = Object.assign({}, formObj);
    },

    //table操作
    //修改
    editTableFun(row, type) {
      console.log(101010);
      console.log(row);
      this.dialogFormVisible = true;
      this.dialogFormVisibleId = row.id;
      type ? this.dialogFormVisibleType = type : this.dialogFormVisibleType = 'set';

      // this.formInfo = Object.assign({}, row);
    },

    //修改
    editTableFun2(row, type) {
      console.log(row);
      let params = { userId: row.id };
      this.dialogFormVisible2 = true;
      this.dialogFormVisible2Id = row.id;
      getUserOrgRelationList({
        params
      }).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.tableFrom = data;
        }
      })
    },
    addtableFrom() {
      this.tableFrom.push(Object.assign({}, tableFromObj))
    },
    edittableFrom(scope) {
      this.tableFrom.splice(scope.$index, 1);
    },

    // 编辑用户状态
    editUserStatus(row) {
      this.$confirm(`确定要${row.status == "2" ? '启用' : '停用'}【${row.name}】吗？`, '操作确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id,
          status: row.status == "1" ? "2" : "1"
        };
        userUpdateStaus(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.queryUserInfo();
          }
        })
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
    },

    // 用户锁定状态
    editUserStatus2(row) {
      console.log(11, row);
      this.$confirm(`确定要删除【${row.name}】吗？`, '操作确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        };
        console.log(222, params);
        streetDelete(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.queryUserInfo();
          }
        })
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },

    //表单新增
    dialogSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
        } else {
          return false;
        }
      });
    },
    submitFormFun() {
      if (Object.prototype.toString.call(this.formInfo.orgId) === '[object Array]') {
        this.formInfo.orgId = this.formInfo.orgId[this.formInfo.orgId.length - 1]
      }
      let params = Object.assign({}, this.formInfo);
      console.log("🚀 ~ file: mechanismManage.vue ~ line 431 ~ submitFormFun ~ params", params)
      var API = this.dialogFormVisibleType == 'add' ? userSave : userUpdate;
      API(params).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
        }
      })
    },
    submitFormFun2() {
      console.log(this.tableFrom);
      let arr = this.tableFrom.map((item) => {
        if (Object.prototype.toString.call(item.orgId) === '[object Array]') {
          item.orgId = item.orgId[item.orgId.length - 1];
        }
        return item
      })

      // //必填验证
      // let flag = arr.every((item)=>{
      //   return (item.orgId && item.peopleType && item.roleId)
      // })
      // if(!flag){
      //   this.$message({
      //     type: 'error',
      //     message: '请将数据填写完整!'
      //   });
      //   return
      // }

      let params = { userId: this.dialogFormVisible2Id, sysUserOrgRelationList: arr };
      console.log("🚀 ~ file: mechanismManage.vue ~ line 451 ~ submitFormFun ~ params", params)
      saveUserOrgRelation(params).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.dialogFormVisible2 = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
        }
      })
    },

    //批量
    auditBatchRelieveIsolate(val) {
      this.multipleSelection = val;
      let ids = this.multipleSelection.map((item) => {
        return item.id
      })
      this.ids = ids;
      console.log(1111111, ids);

      // this.names = names.toString();
    },

    // 获取机构
    async getBusinessOrgList() {
      //加载机构居家隔离业务类型为是的
      let params = { homeIsolation: 1 }
      const { data } = await getBusinessOrgList({params});
      if (data.code == '200') {
        this.xqsqsjoptionscx = data.data;
      }
    },

    // 导入文件
    importfile() {
      this.importfiledialog = true
    },
    // 确定上传文件
    swddd() {
      this.importfiledialog = false
    },

    exportFormInfo() {
      let params = {

      };
      messageBox(() => {
        exportStreetRegTemplate({ params }).then(res => {
          importFile(res.data, '模板')
        })
      }, "warning", '是否确认下载模板？')
    },

    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";


.islaotion {
  margin-left: 100px;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-table {
    overflow-y: auto;
  }
}

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin: 14px;


}

.el-link {
  margin: 20px auto;
}
</style>

<style scoped>
.el-table>>>td {
  padding: 6px 0 !important;
}
</style>